<template>
    <div class="page-wrapper">
        <v-card class="pa-5" v-for="(list, i) in wish_lists">
            <v-card-title>
                {{ list.name }}
                <v-spacer></v-spacer>
                <v-btn
                        color="blue-grey darken-4"
                        @click="delete_list(list, i)"
                >
                    <v-icon>
                        mdi-delete
                    </v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">Img</th>
                            <th class="text-left">Name</th>
                            <th class="text-left">FOB</th>
                            <th class="text-left">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in list.items" :key="index">
                            <td>
                                <v-img
                                        class="white--text"
                                        height="55"
                                        max-width="100"
                                        :src="item.product.album.files[0].url"
                                >
                                </v-img>
                            </td>
                            <td>
                                {{ item.product.name }}
                            </td>
                            <td>
                                {{ item.fob_price | currency }}
                            </td>
                            <td>
                                <v-btn
                                        color="blue-grey darken-4"
                                        fab
                                        text
                                        rounded
                                        class="mr-3"
                                        @click="delete_item(list, item, index)"
                                >
                                    <v-icon>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-btn
                        color="blue-grey darken-4"
                        outlined
                        block

                        @click="add_to_cart(list)"
                >
                    Add To Cart
                </v-btn>
            </v-card-text>
        </v-card>
        <v-card class="px-5 text-center" v-show="!wish_lists.length">
            <h2>
                404 No Wishlists Found
            </h2>
        </v-card>
    </div>
</template>

<style lang="scss">

</style>

<script>
    export default {
        components: {},
        data() {
            return {
                wish_lists: []
            }
        },
        created() {
            this.get_lists();
        },
        methods: {
            get_lists() {
                this.$http.get(
                    this.$http.defaults.baseURL + '/store/wishlist/').then((response) => {
                    console.log(response);
                    this.notification = true;
                    this.notification_message = '';
                    this.wish_lists = response.data.results;
                })
                    .catch((error) => {
                        this.notification = true;
                        this.notification_message = 'Error: Please contact administrator';
                        console.log("Error");
                        console.log(error);
                    });
            },
            delete_item(wishlist, item, index) {
                this.$http.delete(
                    this.$http.defaults.baseURL + '/store/wishlist-item/' + item.id + '/').then((response) => {
                    console.log(response);
                    this.notification = true;
                    this.notification_message = 'Successfully Deleted An Item';
                    wishlist.items.splice(index, 1);
                })
                    .catch((error) => {
                        this.notification = true;
                        this.notification_message = 'Error: Please contact administrator';
                        console.log("Error");
                        console.log(error);
                    });
            },
            add_to_cart(list) {
                this.$http.get(
                    this.$http.defaults.baseURL + '/store/wishlist-add-to-cart/'+list.id+'/').then((response) => {
                    console.log(response);
                    this.notification = true;
                    this.notification_message = 'Successfully added to cart';
                    this.$router.push('/cart')
                })
                    .catch((error) => {
                        this.notification = true;
                        this.notification_message = 'Error: Please contact administrator';
                        console.log("Error");
                        console.log(error);
                    });
            },
            delete_list(list, index) {
                this.$http.delete(
                    this.$http.defaults.baseURL + '/store/wishlist/' + list.id + '/').then((response) => {
                    console.log(response);
                    this.notification = true;
                    this.notification_message = 'Successfully Deleted An Item';
                    this.wish_lists.splice(index, 1);
                })
                    .catch((error) => {
                        this.notification = true;
                        this.notification_message = 'Error: Please contact administrator';
                        console.log("Error");
                        console.log(error);
                    });
            }
        }
    }
</script>
